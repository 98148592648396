"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceMacros = exports.Bid = void 0;
var Analytics_1 = require("./Analytics");
var log_1 = require("../utils/log");
var DEFAULT_BID_EXPIRATION = 30; // 30 seconds
var Bid = /** @class */ (function () {
    function Bid(bidResponse, slotObj) {
        this.floor = 0;
        this.min_bid_to_win = 0;
        this.impression_id = '';
        this.auction_id = '';
        this.bidid = '';
        this.page_id = '';
        this.displayed = false;
        this.demand_channel = '';
        this.gam_price_bucket = '';
        this.data = {
            id: '',
            impid: '',
            price: 0,
            nurl: '',
            burl: '',
            lurl: '',
            adm: '',
            adid: '',
            adomain: [],
            bundle: '',
            iurl: '',
            cid: '',
            crid: '',
            tactic: '',
            cat: [],
            attr: [],
            api: [],
            protocol: 0,
            qagmediarating: 0,
            language: '',
            dealid: '',
            w: 0,
            h: 0,
            wratio: 0,
            hratio: 0,
            exp: DEFAULT_BID_EXPIRATION,
            ext: {},
        };
        // Set slot object
        this.slotObj = slotObj;
        //set floor
        this.floor = bidResponse.floor;
        // Set min bid to win
        this.min_bid_to_win = bidResponse.min_bid_to_win;
        // Set demand type
        this.demand_type = bidResponse.demand_type;
        // Set demand channel
        this.demand_channel = bidResponse.demand_channel;
        // Set bidder name
        this.bidder = bidResponse.bidder;
        // Set bidder name
        this.gam_price_bucket = bidResponse.gam_price_bucket || '';
        // Set impression id
        this.impression_id = bidResponse.impression_id;
        // Set auction id
        this.auction_id = bidResponse.auction_id;
        // Set bid id
        this.bidid = bidResponse.bidid;
        // Set page id
        this.page_id = bidResponse.page_id;
        // Set data
        Object.assign(this.data, bidResponse.data);
        // Set expiration from current time
        this.expiration = Date.now() + ((this.data.exp || DEFAULT_BID_EXPIRATION) * 1000);
        // Set viewability
        this.viewability = {
            timeout_left: 2000,
            timer: null,
            pixel_sent: false,
            video_duration_met: false,
            inview_duration_met: false,
        };
    }
    Bid.prototype.isExpired = function () {
        return Date.now() > this.expiration;
    };
    Bid.prototype.setViewabilityTimer = function () {
        var _this = this;
        // set a timer for 2 seconds if the slot is inview
        if (this.displayed &&
            this.bidder !== 'unfilled' &&
            !this.viewability.pixel_sent &&
            this.viewability.timer === null &&
            this.slotObj.viewability.inview) {
            this.viewability.timer = setTimeout(function () {
                (0, log_1.logInfo)(_this.slotObj.slot_id + ': Viewability duration met');
                _this.viewability.inview_duration_met = true;
                _this.sendViewabilityPixel();
            }, this.viewability.timeout_left);
        }
    };
    // Clear viewability timer but save time since displayed
    Bid.prototype.clearViewabilityTimer = function () {
        if (this.viewability.timer) {
            clearTimeout(this.viewability.timer);
            this.viewability.timer = null;
        }
        if (!this.viewability.pixel_sent) {
            var time_inview_since_displayed = this.slotObj.viewability.time_inview_since_displayed;
            this.viewability.timeout_left = Math.max(this.viewability.timeout_left - time_inview_since_displayed, 0);
        }
    };
    Bid.prototype.setVideoViewabilityMet = function () {
        (0, log_1.logInfo)(this.slotObj.slot_id + ': Video viewability met');
        this.viewability.video_duration_met = true;
        this.sendViewabilityPixel();
    };
    Bid.prototype.sendViewabilityPixel = function () {
        if (!this.viewability.pixel_sent &&
            this.viewability.inview_duration_met &&
            (this.demand_type !== 'video' ||
                this.viewability.video_duration_met)) {
            (0, log_1.logInfo)(this.slotObj.slot_id + ': Sending viewability pixel');
            this.viewability.pixel_sent = true;
            Analytics_1.globalAnalytics.sendAdViewabilityAnalytics(this);
        }
    };
    // Send provisional win notice
    Bid.prototype.sendNurlPixel = function () {
        if (this.data.nurl) {
            // replace macros
            var nurl = replaceMacros(this.data.nurl, this);
            // send impression analytics
            var impression = new Image();
            impression.src = nurl;
        }
    };
    Bid.prototype.sendImpressionAnalytics = function () {
        this.displayed = true;
        this.slotObj.setDisplayed();
        if (this.data.burl) {
            // replace macros
            // replace macros
            var burl = replaceMacros(this.data.burl, this);
            // send impression analytics
            var impression = new Image();
            impression.src = burl;
        }
        Analytics_1.globalAnalytics.sendImpressionAnalytics(this);
    };
    return Bid;
}());
exports.Bid = Bid;
function replaceMacros(url, bid) {
    var _a, _b, _c, _d;
    try {
        return url.replace(/\${AUCTION_ID}/g, bid.auction_id || '')
            .replace(/\${AUCTION_BID_ID}/g, bid.bidid || '')
            .replace(/\${AUCTION_IMP_ID}/g, ((_a = bid.data) === null || _a === void 0 ? void 0 : _a.impid) || '')
            .replace(/\${AUCTION_AD_ID}/g, ((_b = bid.data) === null || _b === void 0 ? void 0 : _b.adid) || '')
            .replace(/\${AUCTION_PRICE}/g, ((_d = (_c = bid.data) === null || _c === void 0 ? void 0 : _c.price) === null || _d === void 0 ? void 0 : _d.toString()) || '')
            .replace(/\${AUCTION_CURRENCY}/g, 'USD');
    }
    catch (e) {
        console.error(e);
        return url;
    }
}
exports.replaceMacros = replaceMacros;
