"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalIdentityAdapeterHandler = void 0;
var _1 = require("../../.");
var ConsentHandler_1 = require("../consent/ConsentHandler");
var activities_1 = require("../../modules/consent/activities");
var log_1 = require("../../utils/log");
var utils_1 = require("../../utils/utils");
var IdentityAdapeterHandler = /** @class */ (function () {
    function IdentityAdapeterHandler() {
        document.addEventListener('BcUserHasedEmail', function (event) {
            var _a, _b;
            if ((_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.SHA256) {
                this.checkUid2((_b = event === null || event === void 0 ? void 0 : event.detail) === null || _b === void 0 ? void 0 : _b.SHA256);
            }
        }.bind(this));
    }
    IdentityAdapeterHandler.prototype.getUserIdsAsEids = function () {
        var _a, _b;
        return (ConsentHandler_1.allConsentHandler.isActivityAllowed(activities_1.ACTIVITY_ENRICH_EIDS, { gvlid: null })) ? ((_a = globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs) === null || _a === void 0 ? void 0 : _a.getUserIdsAsEids) && ((_b = globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs) === null || _b === void 0 ? void 0 : _b.getUserIdsAsEids()) : [];
    };
    IdentityAdapeterHandler.prototype.getUserIds = function () {
        var _a, _b;
        return (ConsentHandler_1.allConsentHandler.isActivityAllowed(activities_1.ACTIVITY_ENRICH_EIDS, { gvlid: null })) ? ((_a = globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs) === null || _a === void 0 ? void 0 : _a.getUserIdsAsEids) && ((_b = globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs) === null || _b === void 0 ? void 0 : _b.getUserIdsAsEids()) : {};
    };
    IdentityAdapeterHandler.prototype.checkUid2 = function (hashedEmail) {
        try {
            globalThis.pbjs.que.push(function () {
                var _a, _b, _c, _d, _e;
                // The name of the UID2 module
                var uid2ModuleName = 'uid2IdSystem';
                var uid2Name = 'uid2';
                // Get the existing userSync configuration
                var existingConfig = typeof ((_a = globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs) === null || _a === void 0 ? void 0 : _a.getConfig) === 'function' && ((_b = globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs) === null || _b === void 0 ? void 0 : _b.getConfig('userSync')) || ((_d = (_c = _1.globalSettings === null || _1.globalSettings === void 0 ? void 0 : _1.globalSettings.prebid) === null || _c === void 0 ? void 0 : _c.config) === null || _d === void 0 ? void 0 : _d.userSync) || {};
                // Get the existing userIds
                var existingUserIds = existingConfig.userIds || [];
                // get the UID2 module configuration
                var uid2Config = existingUserIds.find(function (userId) {
                    return userId.name === uid2Name;
                });
                var uid2Params = (uid2Config === null || uid2Config === void 0 ? void 0 : uid2Config.params) || {};
                if (
                // Check hashed emial
                hashedEmail &&
                    // Check param values
                    !(uid2Params === null || uid2Params === void 0 ? void 0 : uid2Params.emailHash) &&
                    (uid2Params === null || uid2Params === void 0 ? void 0 : uid2Params.subscriptionId) &&
                    (uid2Params === null || uid2Params === void 0 ? void 0 : uid2Params.serverPublicKey) &&
                    // Uid2 value is not set
                    !(globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs.getUserIds().uid2) &&
                    // Uid2 module is installed
                    ((_e = globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs) === null || _e === void 0 ? void 0 : _e.installedModules.indexOf(uid2ModuleName)) !== -1) {
                    // There is no token that can be used or refreshed. 
                    // The UID2 module must be configured with DII to generate a new token. 
                    // https://unifiedid.com/docs/getting-started/gs-normalization-encoding#email-address-hash-encoding
                    uid2Params.emailHash = (0, utils_1.hexToBase64)(hashedEmail); // base64 encode the hashed email
                    var updatedUserIds = [];
                    var containsUid2 = existingUserIds.some(function (userId) { return userId.name === uid2Name; });
                    if (containsUid2) {
                        updatedUserIds = existingUserIds.map(function (userId) {
                            return userId.name === uid2Name ? { name: uid2Name, params: uid2Params } : userId;
                        });
                    }
                    else {
                        updatedUserIds.push({ name: uid2Name, params: uid2Params });
                    }
                    globalThis.pbjs.setConfig({
                        userSync: {
                            userIds: updatedUserIds
                        }
                    });
                    // Refresh specific modules
                    globalThis.pbjs.refreshUserIds({ submoduleNames: [uid2Name] }, function () {
                        (0, log_1.logInfo)("Refreshed Uid2", globalThis === null || globalThis === void 0 ? void 0 : globalThis.pbjs.getUserIds());
                    });
                }
            });
        }
        catch (error) {
            (0, log_1.logError)('Error in UID2', error);
        }
    };
    return IdentityAdapeterHandler;
}());
exports.globalIdentityAdapeterHandler = new IdentityAdapeterHandler();
